<template>
  <div class="container">
    <div class="header">
        <img src="../../../../public/images/home/logo.png" @click="$router.push('/')" alt="logo">
        <div class="right">
            <span class="home" @click="$router.push('/')">首页</span>
            <span class="help" @click="clickHelpCenterHandler()">帮助中心</span> 
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"Login",
    methods:{
        clickHelpCenterHandler(){
            this.$router.push('/helpCenter',()=>{});
        }
    }
}
</script>

<style lang="less" scoped>
.container{
    width: calc(100vw);
    min-width: 1440px;
    height: 106px;
    background: #FFFFFF;
    .header{
        margin: 0 auto;
        width: 1200px;
        height: 106px;
        background: #FFFFFF;
        display: flex;
        justify-content: space-between;
        align-items: center; 
        img{
            width: 185px;
            height: 60px;
            cursor: default;
        }
        .right{
            width: 158px;
            height: 60px;
            display: flex;
            align-items: center;
            .home{
                font-size: 18px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #333333;
                cursor: default;
            }
            .help{
                
                margin-left: 50px;
                font-size: 18px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #333333;
                cursor: default;
            }
        }
    }
}
</style>